export default {
    data() {
        return {
        }
    },
    computed: {
    },
    methods: {
        replaceTemplateTexts(str, captions) {
            if (!str.match(/<svg/)) {
                return str
            }
            let parser = new DOMParser();
            let tmplDom = parser.parseFromString(str,"text/xml")
            let texts = tmplDom.querySelectorAll('text[id]')
            if (!texts) {
                return str
            }
            for (let text of texts) {
                const name = text.getAttribute('id')/*, fontsize = text.getAttribute('font-size')*/
                if (name in captions) {
                    text.textContent = captions[name]
                }
                if (text.hasAttribute('font-size') && `${name}-fontsize` in captions) {
                    let fs = text.getAttribute('font-size').match(/^([\d\\.]*)(.*)$/),
                        newsize = parseFloat(fs[1])*captions[`${name}-fontsize`]/100
                    text.setAttribute('font-size', `${newsize}${fs[2]}`)
                }
            }
            return new XMLSerializer().serializeToString(tmplDom.documentElement)
        },
    }
}