import Vue from 'vue'
import VueRouter from 'vue-router'
import DemoGenerator from "@/components/demo/DemoGenerator";
import {loadLanguageAsync} from "../i18n";

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'main', component: DemoGenerator },
  // { path: '/', name: 'main', component: () => import(/* webpackChunkName: "Generator" */"@/components/demo/DemoGenerator") },
  { path: '/:lang/privacy', name: 'privacy', component: () => import(/* webpackChunkName: "About" */"@/components/about/AboutPrivacy") },
  { path: '/:lang/tos', name: 'tos', component: () => import(/* webpackChunkName: "About" */"@/components/about/AboutTos") },
  // { path: '/:lang', name: 'lang', component: () => import(/* webpackChunkName: "Generator" */"@/components/demo/DemoGenerator") },
  { path: '/:lang', name: 'lang', component: DemoGenerator },
  { path: '/:lang/product', name: 'product', component: () => import(/* webpackChunkName: "About" */"@/components/about/AboutProduct") },
  { path: '/:lang/pricing', name: 'pricing', component: () => import(/* webpackChunkName: "About" */"@/components/about/AboutPricing") },
  { path: '/:lang/api', name: 'api', component: () => import(/* webpackChunkName: "About" */"@/components/about/AboutApi") },
  { path: '/:lang/resources', name: 'resources', component: () => import(/* webpackChunkName: "Resources" */"@/components/about/AboutResources") },
  { path: '/:lang/resources/:slug', name: 'article', component: () => import(/* webpackChunkName: "Resources" */"@/components/about/AboutArticle") },
  { path: '/:lang/contactus', name: 'contactus', component: () => import(/* webpackChunkName: "About" */"@/components/about/AboutContactUs") },
  // { path: '/:lang/:slug', name: 'langandslug', component: () => import(/* webpackChunkName: "Generator" */"@/components/demo/DemoGenerator") },
  { path: '/:lang/:slug', name: 'langandslug', component: DemoGenerator },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async(to, from, next) => {
  if (to.params && to.params.lang) {
    loadLanguageAsync(to.params.lang)
        .then(() => next())
  }
  // TODO should there be 'else'?
  next();
});

export default router
