import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#03949A',
        secondary: '#909295',
        success: "#00ADB5",
        error: '#C9454D',
        info: '#3A82F7'
      },
    },
  },
  icons: {iconfont: 'mdiSvg'}
});
