export default {
    computed: {
        appUrl() { return process.env.VUE_APP_URL },
        proUrl() { return process.env.VUE_APP_PRO_URL },
        languages() { return this.$store.getters.languages },
        languagesMap() { return this.$store.getters.languagesMap },
        pricePlans() { return this.$store.getters.pricePlans },
        countriesNames() { return this.$store.getters.countriesNames },
        isPro() { return this.$store.getters.isPro },
        isAdmin() { return this.$store.getters.isAdmin },
        currentLang() {
            return this.$i18n.locale
        },
        isRTL() {
            var f = this.languages.filter(l => l.short === this.currentLang)
            return f && f.length && f[0].rtl
        },
        currentLangIntl() {
            const f = this.languages.filter(l => l.short === this.currentLang)
            return f.length ? f[0].long : this.currentLang
        },

        apiQrDemoUrl() {
            return process.env.VUE_APP_API + '/demo/qr'
        },

        staticcards() {
            let result = []
            let staticcardsRaw = this.$store.getters.staticcardsRaw
            for (let i in this.languages) {
                let lang = this.languages[i].short
                result[lang] = [];
                for (let shortname in staticcardsRaw) {
                    result[lang].push({...staticcardsRaw[shortname][lang], shortname, hasform: shortname !== 'file'})
                }
            }
            return result
        },

        dynamiccards() {
            let result = []
            let dynamiccardsRaw = this.$store.getters.dynamiccardsRaw
            for (let i in this.languages) {
                let lang = this.languages[i].short
                result[lang] = [];
                for (let shortname in dynamiccardsRaw) {
                    result[lang].push({...dynamiccardsRaw[shortname][lang], shortname})
                }
            }
            return result
        },

        languagesMenu() {
            let res = {}
            if (this.languages) {
                for (let i in this.languages) {
                    res[this.languages[i].short] = this.languages[i].native
                }
            } else {
                res['en'] = 'English'
            }
            return res
        },

        coptions() {
            const imageWithAttrs = function(attrs) {
                    var oImg = document.createElement("img");
                    for (let k in attrs) { oImg.setAttribute(k, attrs[k]) }
                    return oImg.outerHTML
                },
                samplesimg = `/images/samples/${this.isPro ? 'pro' : 'demo'}.svg`,
                getStyleOptions = function(styles, rownum, alldefaults = null) {
                    let rv = []
                    for (let i in styles) {
                        const x = i ? `-${i*60}px` : '0', y = rownum ? `-${rownum*60}px` : '0'
                        let extras = {}
                        if (alldefaults) {
                            const defaults = alldefaults[styles[i]]
                            extras['eyeoutline'] = defaults[0] ? defaults[0] : 'square'
                            extras['eyeball'] = defaults[1] ? defaults[1] : 'square'
                        }
                        const label = `<div style="background-image: url('${samplesimg}'); background-position: ${x} ${y}` +
                            '; width: 60px; height: 60px; background-repeat: no-repeat;">' +
                            imageWithAttrs({'src': '/images/transparent.png', 'width': 60, 'height': 60, ...extras})
                        rv.push({value: styles[i], src: samplesimg, label, ...extras})
                    }
                    return rv
                }

            let coptions = {}
            let smfm = this.$store.getters.smfm
            coptions.style = getStyleOptions(smfm.style, 0, smfm.defaults)
            coptions.eyeoutline = getStyleOptions(smfm.eyeoutline, 1)
            coptions.eyeball = getStyleOptions(smfm.eyeball, 2)
            coptions.gap = getStyleOptions(smfm.gap, 3)
            coptions.fill = getStyleOptions(smfm.fill, 4)

            return coptions
        },

    },
    methods: {
        detectBrowserLang() {
            const available = this.languages.map(l => l.short)
            if (this.isPro && window.localStorage.preferredLang && available.includes(window.localStorage.preferredLang)) {
                return window.localStorage.preferredLang
            }
            const singlelanguage = navigator.language || navigator.browserLanguage || navigator.userLanguage
            const clientlanguages = navigator.languages && Array.isArray(navigator.languages) && navigator.languages.length ?
                navigator.languages : [singlelanguage]
            for (let clientlanguage of clientlanguages) {
                if (available.includes(clientlanguage)) {
                    return clientlanguage
                } else if (clientlanguage.length > 2 && available.includes(clientlanguage.substr(0, 2))) {
                    return clientlanguage.substr(0, 2)
                }
            }
            return 'en'
        },

        compareSlugs(slug, currentSlug = null) {
            currentSlug = (currentSlug === null) ? this.$route.params.slug : currentSlug
            if (!slug) return false
            if (Array.isArray(slug)) {
                for (var i in slug) {
                    if (slug[i] === currentSlug) return true;
                }
                return false
            } else {
                return slug === currentSlug
            }
        },

        acceptPrivacy() {
            window.localStorage.privacyAccepted = 1
        },

        isPrivacyAccepted() {
            return !!window.localStorage.privacyAccepted
        },

        base64encode(str) {
            // https://attacomsian.com/blog/javascript-base64-encode-decode

            // first we use encodeURIComponent to get percent-encoded UTF-8,
            // then we convert the percent encodings into raw bytes which
            // can be fed into btoa.
            return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
                function toSolidBytes(match, p1) {
                    return String.fromCharCode('0x' + p1);
                }));
        },

        base64decode(str) {
            // Going backwards: from bytestream, to percent-encoding, to original string.
            return decodeURIComponent(atob(str).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
        },
        getPlanYearPrice(price, rate) {
            return Math.round(price * rate);
        },
        getPlanMonthPrice(price, rate) {
            let perMonth = (price * rate) / 12;
            if (Number.isInteger(perMonth)) {
                return perMonth;
            } else {
                return perMonth.toFixed(1);
            }
        },

        getArticleFromToc(shortname, lang = null, defaultValue = null) {
            if (!this.$store.getters.resourcesToc) return defaultValue
            if (!lang) lang = this.currentLang
            if (!(lang in this.$store.getters.resourcesToc)) lang = 'en'
            const f = this.$store.getters.resourcesToc[lang].filter(a => a.shortname === shortname)
            return f.length ? f[0] : defaultValue
        },

        getArticleUrl(shortname, lang = null, defaultValue = null) {
            const article = this.getArticleFromToc(shortname, lang)
            if (!article) return defaultValue
            if (!lang) lang = this.currentLang
            if (!(lang in this.$store.getters.resourcesToc)) lang = 'en'
            // TODO: use article.actuallang ?
            return article ? `${lang}/resources/${article.slug}` : defaultValue
        },

        getStaticCardUrl(shortname, lang = null, defaultValue = null) {
            if (!this.staticcards) return defaultValue
            if (!lang) lang = this.currentLang
            if (!(lang in this.staticcards)) lang = 'en'
            const f = this.staticcards[lang].filter(a => a.shortname === shortname)
            return f.length ? `${lang}/${f[0].slug}` : defaultValue
        },

        prepareTextWithLinks(text) {
            const replaceHref = (str, url) => {
                const replacement = this.isPro ?
                    `href="${this.appUrl}/${url}" target="_blank" rel="noopener" class="smfm-externallink"` :
                    `href="/${url}" class="smfm-locallink"`
                return str.replace(/^<a\b([^>]*)\bhref="[^"]*"([^>]*)>/, `<a$1${replacement}$2>`)
            }
            const regex = pattern => new RegExp('<a\\b[^>]*\\bhref="#' + pattern + '"[^>]*>(.*?)</a>', 'g')

            if (!text) return ''
            if (text.match(/#(resource|article):([\w]+)/)) {
                if (this.$store.getters.resourcesToc) {
                    const r = (m, key) => replaceHref(m, this.getArticleUrl(key, null, '#'))
                    text = text.replace(regex('resource:([\\w]*)'), r, text)
                    text = text.replace(regex('article:([\\w]*)'), r, text)
                } else {
                    this.$nextTick(() => this.$store.dispatch('loadResourcesToc'))
                }
            }
            const s = (m, key) => replaceHref(m, this.getStaticCardUrl(key, null, '#'))
            text = text.replace(regex('static:([\\w]*)'), s, text)
            const ab = (m, key) => replaceHref(m, `${this.currentLang}/${key}`)
            text = text.replace(regex('about:([\\w]*)'), ab, text)
            text = text.replace(regex('pro'), m => ab(m, 'product#pro'), text)
            return text
        },

        /**
         * Alternative URLs for the current page in the other languages (demo only)
         *
         * @param {Boolean} strictSearch
         * @returns {Object}
         */
        getAlternatives(strictSearch = true) {
            if (this.isPro || this.isAdmin) return {}
            const currentLang = this.$route.params.lang ? this.$route.params.lang : this.$i18n.currentLang
            let links = {}, shortname = null
            const resourcesToc = this.$store.getters.resourcesToc ? this.$store.getters.resourcesToc : this.$store.getters.resourcesTocStatic

            if (this.$route.name === 'article' && this.$route.params.slug && resourcesToc && (currentLang in resourcesToc)) {
                const findArticle = (el) => el && (this.compareSlugs(el.slug) || this.compareSlugs(el.aliases))
                const f = resourcesToc[currentLang].filter(findArticle)
                if (f.length) shortname = f[0].shortname
            } else if (this.$route.name === 'langandslug' && this.$route.params.slug
                    && (currentLang in this.staticcards)) {
                const f = this.staticcards[currentLang].filter(v=>this.compareSlugs(v.slug))
                if (f.length) shortname = f[0].shortname
            }

            for (let code in this.languagesMenu) {
                let url = '/'+code
                let name = this.$route.name
                if (this.$route.name) {
                    let params = {...this.$route.params, lang: code}
                    // Special treatment for 'article' and 'langandslug' since slug is different in different languages
                    if (this.$route.name === 'article') {
                        if (shortname) {
                            const f2 = resourcesToc[code].filter(v=>v.shortname === shortname)
                            if (f2.length) params.slug = f2[0].slug
                            else name = 'resources'
                        } else name = 'resources'
                    } else if (this.$route.name === 'langandslug') {
                        if (shortname && this.staticcards[code][0].shortname === shortname) {
                            // This is the first, default article, the canonical name for it is different
                            name = 'lang'
                        } else if (shortname) {
                            const f2 = this.staticcards[code].filter(v=>v.shortname === shortname)
                            if (f2.length) params.slug = f2[0].slug
                            else name = 'lang'
                        } else name = 'lang'
                    }
                    url = this.$router.resolve({name, params}).href
                }

                if (strictSearch && (!this.$route.name || name !== this.$route.name)) continue
                links[code] = url
            }

            return links
        }
    }
}
