import axios from "axios";
import qrdownload from "./qrdownload";
const getJsQr = () => import(/* webpackChunkName: "jsqr" */'jsqr');
import templatetext from "@/mixins/templatetext";

export default {
    mixins: [qrdownload, templatetext],
    data() {
        return {
            lastrequestcounter: 0,
            lastrequest: {},
            lastresponse: null
        }
    },
    computed: {
        _currentqr() {
            return this.$store.getters.getCurrentQr
        }
    },
    methods: {
        _setCurrentQr(newValue) {
            return this.$store.dispatch('setCurrentQr', newValue)
        },
        _resetCurrentQr() {
            return this.$store.dispatch('resetCurrentQr')
        },
        _setCurrentQrError() {
            return this.$store.dispatch('setCurrentQrError')
        },
        _setCurrentQrInvalid() {
            return this.$store.dispatch('setCurrentQrInvalid')
        },
        _setCurrentQrLoading() {
            return this.$store.dispatch('setCurrentQrLoading')
        },

        /**
         *
         * @param {String} text
         * @param {Qrstyles} qrstyles
         * @param {String} statictype type of static code (for the stats)
         * @return {Object}
         */
        prepareQueryForApi(text, qrstyles, statictype) {
            const config = qrstyles.config
            return {
                text: text,
                style: config.style,
                eyeball: config.eyeball,
                eyeoutline: config.eyeoutline,
                gap: config.gap,
                gapwidth: config.gap !== 'none' ? parseFloat(config.gapwidth) : 0,
                gapheight: config.gap !== 'none' ? parseFloat(config.gapheight) : 0,
                fill: config.fill,
                errorlevel: ['L', 'M', 'H', 'Q'].includes(config.errorlevel) ? config.errorlevel : '',
                // Not used by server generator but we need them for statistics:
                logo: config.logo,
                template: config.template,
                statictype,
            }
        },

        receivederror: function() {
            this._setCurrentQrError()
            //console.error(resp)
        },

        /**
         *
         * @param {Qrstyles} qrstyles
         */
        updateQr: async function (qrstyles = null) {
            if (!this.lastresponse || !this.lastresponse.image) {
                this._resetCurrentQr()
                return
            }
            let config = qrstyles ? qrstyles.config : null

            let image = this.lastresponse.image, width = this.lastresponse.width, height = this.lastresponse.height
            //let needstransforming = true // TODO make dynamic (replace any color, apply logo, template, etc)
            if (config) {
                let xmlDoc = this.stringToDom(this.lastresponse.image);

                // Add colors.
                let els = xmlDoc.querySelectorAll('use'),
                    gradient = xmlDoc.querySelector('linearGradient'),
                    gradient2 = xmlDoc.querySelector('radialGradient'),
                    primarycolor = config.color
                if (gradient) {
                    gradient.querySelectorAll('stop')[0].setAttribute('style', 'stop-color:'+config.color+';stop-opacity:1')
                    gradient.querySelectorAll('stop')[1].setAttribute('style', 'stop-color:'+config.color2+';stop-opacity:1')
                    primarycolor = 'url("#'+gradient.getAttribute('id')+'")'
                } else if (gradient2) {
                    gradient2.querySelectorAll('stop')[0].setAttribute('style', 'stop-color:'+config.color2+';stop-opacity:1')
                    gradient2.querySelectorAll('stop')[1].setAttribute('style', 'stop-color:'+config.color+';stop-opacity:1')
                    primarycolor = 'url("#'+gradient2.getAttribute('id')+'")'
                }
                els.forEach(el => {
                    let id = el.getAttribute('xlink:href').replace(/_\d+$/, ''),
                        color = primarycolor,
                        eyes = id.match(/^#qr(outline|ball)_eye(.*)$/)
                    if (eyes) color = qrstyles.getEyeColor(eyes[1], eyes[2], primarycolor)
                    if (id === '#qrdots' || id === '#qrpart2') color = config.color2
                    if (id === '#qrpart3') color = config.color3
                    if (id === '#qrpart4') color = config.color4
                    el.setAttribute('style', 'fill: '+color)
                })

                // Add logo
                const logoposition = qrstyles.calculateLogoPosition(this.lastresponse.width, this.lastresponse.height,
                    this.lastresponse.gapwidth, this.lastresponse.gapheight)
                if (qrstyles.haslogo) {
                    let logosrc = await this.getSvg(qrstyles.logoimage.src)
                    let src = 'data:image/svg+xml;base64,' + this.base64encode(this.replaceSvgSrcColor(logosrc, qrstyles.logocolor)),
                        imgnode = this.stringToNode(`<image width="${logoposition.w}" height="${logoposition.h}" `+
                            `x="${logoposition.x}" y="${logoposition.y}" xlink:href="${src}"></image>`)
                    config.logobehind ? xmlDoc.firstChild.prepend(imgnode) : xmlDoc.firstChild.appendChild(imgnode)
                }

                // Apply template
                if (qrstyles.hastemplate) {
                    let templatesrc = await this.getSvg(qrstyles.templateimage.src)
                    let src = this.replaceTemplateTexts(templatesrc, config.captions)
                    src = this.replaceSvgSrcColor(src, qrstyles.templatecolor)
                    const tmplDom = this.stringToDom(src),
                        el = tmplDom.querySelector('#qr[x][y][width][height]')
                    if (el) {
                        const scalex = parseFloat(el.getAttribute('width')) / this.lastresponse.width,
                            scaley = parseFloat(el.getAttribute('height')) / this.lastresponse.height,
                            dx = parseFloat(el.getAttribute('x')),
                            dy = parseFloat(el.getAttribute('y')),
                            transform = el.getAttribute('transform')
                        tmplDom.querySelector('svg').setAttribute('xmlns:xlink', 'http://www.w3.org/1999/xlink')
                        let nd = this.stringToNode(`<g transform="translate(${dx} ${dy}) scale(${scalex} ${scaley})"></g>`)
                        while (xmlDoc.firstChild.firstChild) {
                            nd.appendChild(xmlDoc.firstChild.firstChild)
                        }
                        if (transform) {
                            let nd2 = this.stringToNode(`<g transform="${transform}"></g>`)
                            nd2.appendChild(nd)
                            nd = nd2
                        }
                        el.parentNode.replaceChild(nd, el)
                        xmlDoc = tmplDom
                        width = qrstyles.templateimage.width
                        height = qrstyles.templateimage.height
                    }
                }

                // Add background
                if (config.bgcolor !== 'transparent') {
                    const margin = 0
                    const bgrect = this.stringToNode(`<rect x="${-margin}" y="${-margin}" width="${width+2*margin}" height="${height+2*margin}" fill="${config.bgcolor}"></rect>`)
                    xmlDoc.firstChild.prepend(bgrect)
                }

                image = this.domToString(xmlDoc);
            }
            // Create a copy of the lastresponse object and assign to currentqr, replace image
            this._setCurrentQr(Object.assign(Object.assign({}, this.lastresponse), {
                image: image,
            }))
            this.validateQr(this.lastrequest.text);
        },

        getSvg(src) {
            if (src.startsWith('/')) src = process.env.VUE_APP_URL + src
            return axios.get(src).then((resp) => resp.data)
        },

        replaceSvgSrcColor(svg, newcolor) {
            if (newcolor && newcolor !== '#000000' && svg.match(/<svg/)) {
                svg = svg
                    .replace(/#000000/g, newcolor)
                    .replace(/<svg ([^>]*)>/, function (fullmatch, attrs) {
                        return (attrs.match(/\bfill=".*"/)) ? fullmatch : '<svg ' + attrs + ' fill="' + newcolor + '">'
                    })
            }
            return svg
        },

        /**
         * Refresh QR
         *
         * @param {String} text
         * @param {Qrstyles} qrstyles
         * @param {String} statictype type of static code (for the stats)
         */
        refreshQrDemo: function(text, qrstyles, statictype) {
            var reqdata = this.prepareQueryForApi(text, qrstyles, statictype)
            if (JSON.stringify(reqdata) === JSON.stringify(this.lastrequest)) {
                // TODO pretend we are loading :)
                //console.log('Nothing changed!')
                this.updateQr(qrstyles)
                return
            }
            //console.log(reqdata)
            var lastreqtext = this.lastrequest.text
            this.lastrequest = Object.assign({}, reqdata) // Clone reqdata
            if (this.lastresponse && lastreqtext === reqdata.text) {
                reqdata.mx = this.lastresponse.mx
            }

            this._setCurrentQrLoading()
            this.lastrequestcounter++
            const requestcounter = this.lastrequestcounter // In case of multiple async requests
            axios.post(this.apiQrDemoUrl, reqdata)
                .then(resp => {
                    if (requestcounter === this.lastrequestcounter) {
                        this.lastresponse = resp.data
                        this.updateQr(qrstyles)
                    }
                })
                .catch(this.receivederror)

        },

        refreshQrPro: function(promise, textForValidation) {
            // TODO prepareQueryForApi is needed here too, we need to exclude fields:
            // changelogocolor, changetemplatecolor, eyecolor, eyestyle, gapcustom, [logopositioning]
            this._setCurrentQrLoading()
            this.lastrequest = { text: textForValidation }
            this.lastrequestcounter++
            const requestcounter = this.lastrequestcounter // In case of multiple async requests

            promise
                .then(resp => {
                    if (requestcounter === this.lastrequestcounter) {
                        this.lastresponse = resp.data
                        this.updateQr()
                    }
                })
                .catch(this.receivederror)

        },

        validateQr(expectedText) {
            let canvas
            this.prepareCanvasFromSvg(this._currentqr.image, 150, true)
                .then(result => {
                    canvas = result
                    return getJsQr()
                })
                .then(jsqr => {
                    var imgdata = canvas.getContext("2d").getImageData(0, 0, canvas.width, canvas.height)
                    const code = jsqr.default(imgdata.data, imgdata.width, imgdata.height, {})
                    if (!code || code.data !== expectedText) {
                        this._setCurrentQrInvalid()
                    }
                })
        },

    },

}
