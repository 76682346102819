import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import { default as app } from "./modules/app";
import { default as app_demo } from "./modules/app_demo";
import { default as currency } from "./modules/currency";
import { default as currentqr } from "./modules/currentqr";
import { default as library_demo } from "./modules/library_demo";
import { default as resources } from "./modules/resources";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        library_demo,
        resources,
        app,
        app_demo,
        currentqr,
        currency
    },
    plugins: [
        createPersistedState({
            key: "globalstate",
            paths: [
                "currency",
            ]
        }),
    ],
})
