export default {
methods: {
triggerDownload(imgURI, fileName) {
    var evt = new MouseEvent("click", {
        view: window,
        bubbles: false,
        cancelable: true
    });
    var a = document.createElement("a");
    a.setAttribute("download", fileName);
    a.setAttribute("href", imgURI);
    a.setAttribute("target", '_blank');
    a.dispatchEvent(evt);
    return fileName
},

triggerDownloadSvg(svg, fileName) {
    return this.triggerDownload('data:image/svg+xml;base64,' + this.base64encode(svg), fileName)
},

/**
 * Trigger download with the results of authPostBinary request
 *
 * @param {Object} resp response from authPostBinary request
 * @param {String} fileName name of the file to save as (the extension may be changed to .zip if result is a zip)
 */
triggerDownloadBinary(resp, fileName) {
    // TODO this might need a different solution for the very large files, see also:
    // https://stackoverflow.com/questions/46932213/how-to-download-large-file-with-javascript
    const blob = new Blob([resp.data], {type: resp.headers['content-type']});
    const objectUrl = URL.createObjectURL(blob);
    if (resp.headers['content-type'] === 'application/zip') {
        fileName = fileName.replace(/\.[\w]{3,4}$/, '.zip')
    }
    return this.triggerDownload(objectUrl, fileName)
},

sanitiseFilename(txt) {
    // From: https://github.com/parshap/node-sanitize-filename/blob/master/index.js (modified)
    // eslint:
    var illegalRe = /[\/\?<>\\:\*\|"\+ ]/g // eslint-disable-line no-useless-escape
    var controlRe = /[\x00-\x1f\x80-\x9f]/g // eslint-disable-line no-control-regex
    var reservedRe = /^\.+$/;
    var windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i;
    var windowsTrailingRe = /[\. ]+$/; // eslint-disable-line no-useless-escape
    var replacement = '_';

    var sanitized = txt
        .replace(/https?:\/\//, '')
        .replace(illegalRe, replacement)
        .replace(controlRe, replacement)
        .replace(reservedRe, replacement)
        .replace(windowsReservedRe, replacement)
        .replace(windowsTrailingRe, replacement)
        .replace(/_+/, '_');
    return sanitized.substr(0, 32);
},

locateImage(target) {
    var getClosest = function (elem, selector) {
        for (; elem && elem !== document; elem = elem.parentNode) {
            if (elem.matches(selector)) return elem;
        }
        return null;
    };

    if (target.getAttribute('data-context') === 'listing') {
        var tr = getClosest(target, 'tr')
        if (!tr) return null
        return tr.querySelector('.tablecell__thumb img')
    } else if (target.getAttribute('data-context') === 'edit') {
        return document.getElementById('qr__code__preview__img')
    } else if (target.getAttribute('data-context') === 'demo') {
        return document.getElementsByClassName('demo-qr-image')[0].children[0];
    } else {
        return null
    }
},

getDownloadLabel(target) {
    if (target.getAttribute('data-context') === 'demo') {
        var container = document.getElementsByClassName('demo-image-container')[0];
        return container.getElementsByClassName('demo-qr-label')[0].textContent;
    } else {
        return target.getAttribute('data-label')
    }
},

prepareCanvasFromSvg(svg, width, extrabackground = false) {
    return new Promise((resolve) => {
        const origimg = new Image();
        origimg.src = 'data:image/svg+xml;base64,' + this.base64encode(svg)
        origimg.onload = function() {
            let height = width * origimg.naturalHeight / origimg.naturalWidth
            // From: https://stackoverflow.com/a/44769098
            let canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;
            let ctx = canvas.getContext("2d");
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            if (extrabackground) {
                ctx.fillStyle = 'white';
                ctx.fillRect(0, 0, width, height);
            }
            ctx.drawImage(origimg, 0, 0, origimg.naturalWidth, origimg.naturalHeight,
                0, 0, width, height);
            resolve(canvas)
        }
    })
},

stringToDom(str) {
    if (str.match(/^data:.*?,/)) {
        str = this.base64decode(str.replace(/^data:.*?,/, ''))
    }
    let parser = new DOMParser();
    return parser.parseFromString(str,"text/xml");
},

stringToNode(str) {
    return this.stringToDom('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">' + str + '</svg>').firstChild.firstChild
},

domToString(xmlObject) {
    return new XMLSerializer().serializeToString(xmlObject.documentElement);
},

prepareSvgForEmbed(svg) {
    return svg
        .replace(/^<?xml[^>]*>/, '')
        .trim()
        .replace(/^<svg[^>]*>([^\0]*)<\/svg>$/m, '$1')
},

resizeSvgNatural(svg, width, margin=0) {
    return new Promise((resolve) => {
        const origimg = new Image()
        origimg.src = 'data:image/svg+xml;base64,' + this.base64encode(svg)
        origimg.onload = () => {
            if (width === origimg.naturalWidth && margin === 0) {
                return resolve(svg)
            }
            let imgwidth = width - 2 * margin,
                imgheight = imgwidth * origimg.naturalHeight / origimg.naturalWidth,
                height = imgheight + 2 * margin
            let oldsvg = this.prepareSvgForEmbed(svg)
            let svg2 = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${width}" height="${height}"
viewBox="0 0 ${width} ${height}"><g transform="translate(${margin} ${margin}) scale(${imgwidth/origimg.naturalWidth})">${oldsvg}</g></svg>`
            return resolve(svg2)
        }
    })
},

/**
 * Generate file name for downloaded QR code
 *
 * @param {String} qrlabel label for this QR (if necessary to override, otherwise will be taken from the QR code or use timestamp)
 * @param {Object} params parameters as emitted by QrDownloadDialog
 * @param {String} extension file extension (if necessary to override, otherwise will be taken from params.format)
 * @returns {string}
 */
getFilenameForDownload(qrlabel, params, extension = null) {
    let qrlabeldisplay = qrlabel
    if (!qrlabeldisplay) {
        if (params.codes.length === 1) {
            qrlabeldisplay = params.codes[0].qrlabel ? params.codes[0].qrlabel : 'download'
        } else {
            // TODO add timestamp or something unique to the file name?
            qrlabeldisplay = 'download'
        }
    }
    return 'QR_' + this.sanitiseFilename(qrlabeldisplay) + '.' +
        (extension ? extension : params.format);
},

/**
 * Download QR code(s) for PRO site
 *
 * @param {String} qrlabel label for this QR (if necessary to override, otherwise will be taken from the QR code or use timestamp)
 * @param {Object} params parameters as emitted by QrDownloadDialog
 * @param {Function} authGetBinary function ref (because this mixin can not access userMixin)
 * @param {Function} authPostBinary function ref (because this mixin can not access userMixin)
 * @returns {Promise} promise that returns string with the file name
 */
downloadQrCodesPro(qrlabel, params, authGetBinary, authPostBinary) {
    const qrcodes = params.codes
    const advanced = params.advancedoptions || qrcodes.length > 1

    let fileName = this.getFilenameForDownload(this.qrlabel, params);
    if (params.format === 'svg' && !advanced) {
        // When SVG is requested we resize it in the JS and return immediately.
        return this.resizeSvgNatural(qrcodes[0].image, params.size)
            .then(svg => this.triggerDownloadSvg(svg, fileName));
    } else if (!advanced) {
        // PRO site, simple version - retrieve the image that is potentially cached.
        const url = qrcodes[0].qrurl.replace(new RegExp("download/.*?/([^\\.]*)\\..*$","gm"),
            `download/${params.size}dpi${params.dpi}/$1.${params.format}`)
        return authGetBinary({url})
            .then(resp => this.triggerDownloadBinary(resp, fileName))
    } else {
        // On the "PRO" site we convert on the server. Advanced version.
        params.codes = qrcodes.map((code) => code.codeid)
        return authPostBinary({url: 'pro/codesbulk/download', params})
            .then(resp => this.triggerDownloadBinary(resp, fileName))
    }
},

/**
 * Download QR code(s) for DEMO site
 *
 * @param {String} qrlabel label for this QR (if necessary to override, otherwise will be taken from the QR code or use timestamp)
 * @param {Object} data parameters as emitted by QrDownloadDialog
 * @returns {Promise} promise that returns string with the file name
 */
downloadQrCodesDemo(qrlabel, data) {
    const format = data.format
    const size = data.size
    const qrcode = data.codes[0]

    const fileName = this.getFilenameForDownload(qrlabel, data)
    if (format === 'svg') {
        // When SVG is requested we resize it in the JS and return immediately.
        return this.resizeSvgNatural(qrcode.image, size)
            .then(svg => this.triggerDownloadSvg(svg, fileName));
    } else {
        // On the demo site we convert in the browser.
        return this.prepareSvgEmbedFonts(qrcode.image, format !== 'svg')
            .then((svg) => this.prepareCanvasFromSvg(svg, size))
            .then(canvas => {
                const mimetype = "image/" + (format === 'jpg' ? 'jpeg' : format)
                const imgURI = canvas
                    .toDataURL(mimetype)
                    .replace(mimetype, "image/octet-stream");
                return this.triggerDownload(imgURI, fileName);
            })
    }
},

/**
 * For DEMO download add imported fonts to the SVG file because canvas does not process @import for security reasons
 *
 * @param text
 * @param convert
 * @returns {Promise}
 */
prepareSvgEmbedFonts(text, convert = true) {
    const matchAll = (exp, text, grpno) => {
        let res = []
        const regEx = new RegExp(exp, 'g')
        let match = regEx.exec(text)
        while (match != null) {
            res.push(match[grpno])
            match = regEx.exec(text)
        }
        return res
    }

    let fontsurls = matchAll("@import\\s+url\\('([^']*)'\\)", text, 1)
    if (!convert || !fontsurls.length) {
        return new Promise((resolve) => {resolve(text)})
    }

    let svgDoc = new DOMParser().parseFromString(text, 'image/svg+xml');
    let promises = []
    for (let url of fontsurls) promises.push(this.GFontToDataURI(url))
    return Promise.allSettled(promises)
        .then(res => { // we've got our array with all the cssRules
            let allRules = res.map(a=>a.value.join('\n')).join('\n')
            let svgNS = "http://www.w3.org/2000/svg";
            // so let's append it in our svg node
            let defs = svgDoc.createElementNS(svgNS, 'defs')
            let style = svgDoc.createElementNS(svgNS, 'style')
            style.innerHTML = allRules
            defs.appendChild(style)
            svgDoc.documentElement.appendChild(defs)
            // now we're good to create our string representation of the svg node
            return new XMLSerializer().serializeToString(svgDoc.documentElement)
        })
},

/*
  Fetches a URL with an external font definition and converts it to the set of CSS styles
  From https://stackoverflow.com/questions/42402584/how-to-use-google-fonts-in-canvas-when-drawing-dom-objects-in-svg

  works on google fonts but not guaranteed to work on other fonts

  @Params : an url pointing to an embed Google Font stylesheet
  @Returns : a Promise, fulfiled with all the cssRules converted to dataURI as an Array
*/
GFontToDataURI(url) {
    return fetch(url) // first fecth the embed stylesheet page
        .then(resp => resp.text()) // we only need the text of it
        .then(text => {
            // now we need to parse the CSSruleSets contained
            // but chrome doesn't support styleSheets in DOMParsed docs...
            let s = document.createElement('style');
            s.innerHTML = text;
            document.head.appendChild(s);
            let styleSheet = s.sheet

            // this will help us to keep track of the rules and the original urls
            let FontRule = rule => {
                let src = rule.style.getPropertyValue('src') || rule.style.cssText.match(/url\(.*?\)/g)[0];
                if (!src) return null;
                let url = src.split('url(')[1].split(')')[0];
                return {
                    rule: rule,
                    src: src,
                    url: url.replace(/"/g, '')
                };
            };
            let fontRules = [],
                fontProms = [];

            // iterate through all the cssRules of the embedded doc
            // Edge doesn't make CSSRuleList enumerable...
            for (let i = 0; i < styleSheet.cssRules.length; i++) {
                let r = styleSheet.cssRules[i];
                let fR = FontRule(r);
                if (!fR) {
                    continue;
                }
                fontRules.push(fR);
                fontProms.push(
                    fetch(fR.url) // fetch the actual font-file (.woff)
                        .then(resp => resp.blob())
                        .then(blob => {
                            return new Promise(resolve => {
                                // we have to return it as a dataURI
                                //   because for whatever reason,
                                //   browser are afraid of blobURI in <img> too...
                                let f = new FileReader();
                                f.onload = () => resolve(f.result);
                                f.readAsDataURL(blob);
                            })
                        })
                        .then(dataURL => {
                            // now that we have our dataURI version,
                            //  we can replace the original URI with it
                            //  and we return the full rule's cssText
                            return fR.rule.cssText.replace(fR.url, dataURL);
                        })
                )
            }
            document.head.removeChild(s); // clean up
            return Promise.all(fontProms); // wait for all this has been done
        });
},

// downloadQr(e) {
//     // console.log('downloading')
//     // console.log(e)
//     e.preventDefault()
//     var target = e.target.getAttribute('data-context') ? e.target : e.target.parentElement
//     var format = target.getAttribute('data-downloadqr')
//     var origimg = this.locateImage(target)
//     //var origimg = this.getContainer().getElementsByClassName('demo-qr-image')[0].children[0];
//     if (!origimg) {
//         return;
//     }
//     //var txt = this.getContainer().getElementsByClassName('demo-qr-label')[0].textContent
//     var txt = this.getDownloadLabel(target)
//     var fileName = 'QR_' + this.sanitiseFilename(txt) + '.' + format;
//
//     if (format === 'svg') {
//         this.triggerDownload(origimg.getAttribute('src'), fileName);
//         return;
//     }
//
//     // From: https://stackoverflow.com/a/44769098
//     var canvas = document.createElement("canvas");
//     canvas.width = origimg.naturalWidth;
//     canvas.height = origimg.naturalHeight;
//     var ctx = canvas.getContext("2d");
//     ctx.clearRect(0, 0, canvas.width, canvas.height);
//     ctx.drawImage(origimg, 0, 0);
//     var imgURI = canvas
//         .toDataURL("image/" + format)
//         .replace("image/" + format, "image/octet-stream");
//     this.triggerDownload(imgURI, fileName);
// }
}
}
//document.querySelectorAll('[data-downloadqr]').forEach(item => item.addEventListener('click', downloadQr))
