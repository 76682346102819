import Vue from 'vue'
import Demo from './views/Demo'
import demo from './router/demo'
import store from './store/index-demo'
import vuetify from './plugins/vuetify'
import {i18n} from './i18n'
import globalMixin from "@/mixins/globalMixin";
import Router from 'vue-router';
import "@/assets/shared/scss/smfm.scss";
import "@/assets/shared/scss/vuetify.scss";
import "@/assets/demo/scss/global.scss";

// https://www.npmjs.com/package/vcards-js
window.Vcard = require('vcards-js')

// Disable annoying message "You are running Vue in development mode"
Vue.config.productionTip = false

// Preload all dynamic and static components
let files = require.context('@/components/static', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

// Special components that are used in QrStyles, this is shared between demo and pro but
// the advanced components should be loaded only in pro:
import DemoEmpty from './components/demo/DemoEmpty'
Vue.component('pro-save-preset', DemoEmpty)
Vue.component('input-file', DemoEmpty)

Vue.mixin(globalMixin)
new Vue({
    router: demo,
    store,
    vuetify,
    i18n,
    render: h => h(Demo)
}).$mount('#app')
