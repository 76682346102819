
const smfm = require('@/smfm/demo.json')
const languagesMap = require('@/smfm/languagesmapdemo.json')

export default {
    //namespaced: true,

    state: () => ({
        smfm: Object.freeze(smfm),
        languagesMap: Object.freeze(languagesMap),
    }),

    getters: {
        smfm(state) { return state.smfm },
        countriesNames() { return {} },
        isPro() { return false },
        isAdmin() { return false },
        languagesMap(state) { return state.languagesMap },
    },

    mutations: {
    },

    actions: {
    }
}
