import axios from "axios";
const tocStatic = require('@/smfm/resources.json')

export default {
    //namespaced: true,

    state: () => ({
        articles: {},
        toc: null,
        tocloading: false,
        tocStatic: Object.freeze(tocStatic),
    }),

    getters: {
        articles(state) {
            return state.articles
        },
        resourcesToc(state) {
            return state.toc
        },
        resourcesTocStatic(state) {
            return state.tocStatic
        },
        resourcesTocLoaded(state) {
            return state.toc ? 1 : 0
        }
    },

    mutations: {
        _addArticle(state, {lang, article}) {
            state.articles[lang+':'+article.shortname] = article
        },
        _setToc(state, payload) {
            state.toc = payload
            state.tocloading = false
        },
        _setTocLoading(state, payload) {
            state.tocloading = payload
        },
    },

    actions: {
        loadArticle({state, commit}, {lang, shortname}) {
            let loadedarticles = state.articles ? state.articles : {}
            const key = lang + ':' + shortname
            if (key in loadedarticles) {
                return loadedarticles[key]
            }
            return axios.get(`${process.env.VUE_APP_API}/demo/blog/${shortname}?lang=${lang}`)
                .then(resp => {
                    let article = resp.data
                    let actuallang = lang in article ? lang : 'en'
                    article = {...article['en'], ...article[actuallang], shortname, actuallang}
                    return commit('_addArticle', {lang, article})
                })
                .then(() => state.articles[key])
        },

        loadResourcesToc({commit, state, getters}, force = false) {
            if (!force && getters.resourcesTocLoaded) {
                return null
            }
            if (state.tocloading) {
                return new Promise(function (resolve) {
                    (function waitForToc() {
                        if (getters.resourcesTocLoaded) return resolve();
                        setTimeout(waitForToc, 100);
                    })();
                });
            }
            commit('_setTocLoading', true)
            return axios.get(process.env.VUE_APP_API + '/demo/blog')
                .then(resp => {
                    let articles = {}
                    for (let language of getters.languages) {
                        const lang = language.short
                        articles[lang] = []
                        for (let shortname in resp.data) {
                            let article = resp.data[shortname]
                            let actuallang = lang in article ? lang : 'en'
                            articles[lang].push({...article['en'], ...article[actuallang], shortname, actuallang})
                        }
                    }
                    commit('_setToc', articles)
                })
        }
    }
}