
export default {
    name: 'dmf',
    methods: {
        dmfescapevalue: function(value, ismecard = false) {
            // https://www.openmobilealliance.org/release/MobileCodes/V1_0-20130611-A/OMA-TS-MC-V1_0-20130611-A.pdf
            //  The following characters; comma, “,”, semicolon, “;”, colon, “:”, and back slash, “\”,
            //  SHALL be escaped as defined in Section 7.1.2.1 using a back slash, “%x5c”,
            //  if such a character needs to be included in the Property-Value.
            if (ismecard) {
                return value.replace(/;/g, '. ')
            } else {
                return value.replace(/([\\:;,])/g, '\\$1')
            }
        },

        dmfescape: function(prefix, value, optional=false, ismecard = false) {
            if (Array.isArray(value)) {
                value.forEach(function(part, index, ar) {
                    ar[index] = this.dmfescapevalue(part, ismecard)
                }.bind(this));
                return prefix + ':' + value.join(',') + ';'
            }

            value = '' + value
            if (!value.length) {
                return optional ? '' : prefix + ':;'
            }
            return prefix + ':' + this.dmfescapevalue(value, ismecard) + ';'
        },

        dmf: function(protocol, attrs, optional = []) {
            var s = protocol + ':', ismecard = protocol === 'MECARD', key
            for (var i in attrs) {
                key = i.replace(/__\d$/g, '')
                s = s + this.dmfescape(key, attrs[i], optional.includes(key), ismecard)
            }
            return s
        },

        df: function(protocol, p1, p2 = null) {
            return protocol + ':' + p1 + (p2 === null || p2.length === 0 ? '' : (':' + p2))
        },

        escape_phone: function (value, stripplus = false) {
            if (stripplus) {
                return value.replace(/[^\d]/g, '')
            } else {
                return value.replace(/[^\d\+]/g, '') // eslint-disable-line no-useless-escape
            }
        }
    }
}
