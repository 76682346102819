<template>
    <v-app v-cloak class="smfm-app-wrapper demo"
        :class="{'gray-bg': $route.name === 'langandslug' || $route.name === 'contactus'}">
        <!-- Header -->
        <v-app-bar app fixed elevate-on-scroll class="smfm-header"
            :height="$vuetify.breakpoint.smAndDown ? 60 : 80">
            <v-container class="d-flex align-center" :class="{'pa-0': $vuetify.breakpoint.smAndDown}">
                <v-toolbar-title v-cloak>
                    <router-link exact
                        :to="titleLink"
                        class="d-flex align-center">
                        <img :src="$vuetify.breakpoint.smAndDown ? '/images/logo-mobile.svg' : '/images/logo.svg'"
                             :width="$vuetify.breakpoint.smAndDown ? 194 : 140"
                             :height="$vuetify.breakpoint.smAndDown ? 44 : 50"
                             alt="ScanMeFindMe">
                    </router-link>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items class="d-md-flex d-none">
                    <v-btn v-for="item in menu" :key="item.path"
                        text :exact="item.name !== 'resources' ? true : false"
                        :ripple="false"
                        :to="item.qrgen ? titleLink : { name: item.name, params: {lang: currentLang} }">
                        {{ $t(item.title) }}
                    </v-btn>
                </v-toolbar-items>
                <v-spacer></v-spacer>
                <!-- Language selector -->
                <div class="d-md-flex d-none">
                    <language-selector></language-selector>
                    <demo-launch-pro>
                        <template slot-scope="{proceed}">
                            <v-btn class="smfm-primary-btn" @click="proceed()">
                                {{ $t('app.proaccount') }}
                            </v-btn>
                        </template>
                    </demo-launch-pro>
                </div>
                <div class="d-block d-md-none">
                    <v-btn icon small @click="isMobileMenu = !isMobileMenu" :aria-label="$t('app.menu')">
                        <v-icon>
                            {{ !isMobileMenu ? iconMenu : iconClose }}
                        </v-icon>
                    </v-btn>
                </div>
            </v-container>
        </v-app-bar>
        <!-- Main content -->
        <v-main>
            <router-view v-if="languageconfirmed" keep-alive></router-view>
        </v-main>
        <!-- Footer -->
        <v-footer id="footer" inset v-cloak min-height="40"
            class="d-flex flex-column flex-md-row justify-center align-center smfm-footer"
            :class="{'py-6': $vuetify.breakpoint.smAndDown}">
                <v-btn v-for="item in footerMenu" :key="item.path" 
                    text exact :ripple="false"
                    :to="{ name: item.name, params: {lang: currentLang} }"
                    x-small :large="$vuetify.breakpoint.smAndDown">
                    {{ $t(item.title) }}
                </v-btn>
        </v-footer>
        <!-- Menu drawer -->
        <v-navigation-drawer
            v-model="isMobileMenu"
            absolute
            temporary
            dark
            fixed
            width="100%"
            height="auto"
            class="mobile-menu">
            <v-list nav dense>
                <v-list-item v-for="item in menu" :key="item.path" class="mobile-menu-item">
                    <v-list-item-content class="py-1">
                        <v-list-item-title>
                            <v-btn exact text :ripple="false"
                                :to="item.qrgen ? titleLink : { name: item.name, params: {lang: currentLang} }">
                                {{ $t(item.title) }}
                            </v-btn>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <div class="px-3">
                <language-selector :isMobileMenu="isMobileMenu"></language-selector>
            </div>
            <div class="pa-5">
                <demo-launch-pro>
                    <template slot-scope="{proceed}">
                        <v-btn large :block="$vuetify.breakpoint.xsOnly" class="smfm-primary-btn" @click="proceed()">
                            {{ $t('app.proaccount') }}
                        </v-btn>
                    </template>
                </demo-launch-pro>
            </div>
        </v-navigation-drawer>
    </v-app>
</template>

<script>
    import LanguageSelector from "@/components/forms/LanguageSelector";
    import DemoLaunchPro from "@/components/demo/DemoLaunchPro";
    import { mdiMenu, mdiClose } from '@mdi/js';
    import {loadLanguageAsync} from "../i18n";

    export default {
        name: 'Demo',
        components: {DemoLaunchPro, LanguageSelector},
        data() {
            return {
                appname: '',
                languageconfirmed: false,
                isMobileMenu: false,
            }
        },
        computed: {
            slug() { return this.$route.params.slug },
            titleLink() {
                return (this.slug && this.$route.name === 'langandslug') ?
                    { name: 'langandslug', params: {lang: this.currentLang, slug: this.slug} } :
                    { name: 'lang', params: {lang: this.currentLang} }
            },
            iconMenu() { return mdiMenu },
            iconClose() { return mdiClose },
            menu() {
                return [
                    { path: `/${this.currentLang}`, name: 'langandslug', title: 'app.qrgenerator', qrgen: true},
                    { path: `/${this.currentLang}/product`, name: 'product', title: 'app.product.title'},
                    { path: `/${this.currentLang}/pricing`, name: 'pricing', title: 'app.pricing.pricing'},
                    { path: `/${this.currentLang}/api`, name: 'api', title: 'app.api.title'},
                    { path: `/${this.currentLang}/resources`, name: 'resources', title: 'app.resources.title'},
                ]
            },
            footerMenu() {
                return [
                    { path: `/${this.currentLang}/privacy`, name: 'privacy', title: 'app.privacypage'},
                    { path: `/${this.currentLang}/tos`, name: 'tos', title: 'app.tospage'},
                    { path: `/${this.currentLang}/contactus`, name: 'contactus', title: 'app.contact.contactus'},
                ]
            }
        },
        mounted() {
            // Convert all <a href="" class="smfm-locallink"> links on the demo site to be the router links
            // Taken from https://dennisreimann.de/articles/delegating-html-links-to-vue-router.html
            window.addEventListener('click', event => {
                // ensure we use the link, in case the click has been received by a subelement
                let { target } = event
                while (target && target.tagName !== 'A') target = target.parentNode
                // handle only links that do not reference external resources
                if (target && target.matches("a:not([href*='://']).smfm-locallink") && target.href) {
                    // some sanity checks taken from vue-router:
                    // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
                    const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = event
                    // don't handle with control keys
                    if (metaKey || altKey || ctrlKey || shiftKey) return
                    // don't handle when preventDefault called
                    if (defaultPrevented) return
                    // don't handle right clicks
                    if (button !== undefined && button !== 0) return
                    // don't handle if `target="_blank"`
                    if (target && target.getAttribute) {
                        const linkTarget = target.getAttribute('target')
                        if (/\b_blank\b/i.test(linkTarget)) return
                    }
                    // don't handle same page links/anchors
                    const url = new URL(target.href)
                    const to = url.pathname
                    if (window.location.pathname !== to && event.preventDefault) {
                        event.preventDefault()
                        this.$router.push(to + (url.hash ? url.hash : ''))
                    }
                }
            })
        },
        created() {
            this.appname = process.env.VUE_APP_NAME
            // Detect language
            const available = this.languages.map(l => l.short)
            let pathparts = window.location.pathname.replace(/^\//, '').split('/'),
                pathlanguage = pathparts.length ? pathparts[0] : '',
                needRedirect = !pathlanguage || !available.includes(pathlanguage),
                pageLang = needRedirect ? this.detectBrowserLang() : pathlanguage
            loadLanguageAsync(pageLang)
                .then(() => {
                    if (needRedirect) this.$router.replace('/' + pageLang)
                    this.languageconfirmed = true
                    // Dispatch event to notify PrerenderSPAPlugin that page is loaded
                    document.dispatchEvent(new Event('initial-language-loaded'))
                })
        },
        for_translations() {
            // Dummy function so that translations detector does not delete the titles used in menu.
            this.$t('app.qrgenerator')
            this.$t('app.product.title')
            this.$t('app.pricing.pricing')
            this.$t('app.api.title')
            this.$t('app.resources.title')
            this.$t('app.privacypage')
            this.$t('app.tospage')
            this.$t('app.contact.contactus')
        }
    }
</script>

<style scoped lang="scss">
.smfm-app-wrapper {
    .v-btn {
        color: #fff;
        &:hover,
        &.v-btn--active {
            color: #12C7D0;
        }
    }
    .smfm-footer {
        .v-btn {
            color: #EEEEEE;
            font-weight: normal;
            font-size: 14px;
            &:hover {
                color: #909295;
            }
            &.v-btn--active {
                color: #12C7D0;
            }
        }
    }
    .mobile-menu {
        top: 60px !important;
        background: #393E46 !important;
        box-shadow: none !important;
        .v-list-item {
            border-radius: 0 !important;
            &.mobile-menu-item {
                box-shadow: inset 0px -1px 0px rgba(144, 146, 149, 0.3);
            }
            .v-btn--router {
                width: 100%;
                justify-content: flex-start;
            }
        }
    }
}
</style>
