import axios from "axios";

function initialState() {
  return {
    currentCurrCode: 'EUR',
    currencies: [
      // Currencies and approximate rates:
      { code: 'EUR', label: 'Euro', symbol: '€', rate: 1 },
      { code: 'USD', label: 'US Dollar', symbol: '$', rate: 1.0556 },
      { code: 'AUD', label: 'Australian Dollar', symbol: '$', rate: 1.4825 },
      { code: 'CAD', label: 'Canadian Dollar', symbol: '$', rate: 1.3570 },
      { code: 'GBP', label: 'Pound Sterling', symbol: '£', rate: 0.84130 },
    ],
    // Actual currencies rates updated from the API:
    currenciesRates: null,
    lastUpdate: 0,
  }
}

export default {
  namespaced: true,
  state: initialState,

  getters: {
    currentCurrCode(state) { return state.currentCurrCode },
    currencies(state) { return state.currencies },
    currentCurrency(state) {
      let c = state.currencies.find(curr => curr.code === state.currentCurrCode);
      if (state.currenciesRates && state.currenciesRates[state.currentCurrCode] && !isNaN(state.currenciesRates[state.currentCurrCode])) {
        c.rate = state.currenciesRates[state.currentCurrCode]
      }
      return c
    }
  },

  mutations: {
    _setCurrentCurr(state, payload) {
      state.currentCurrCode = payload
    },
    _setCurrentRates(state, payload) {
      let rates = {}
      for (const code in payload) {
        rates[code] = Number(payload[code])
      }
      state.currenciesRates = rates
      state.lastUpdate = Math.floor(Date.now() / 1000)
    },
    _resetState(state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      });
    }
  },

  actions: {
    setCurrentCurr({commit}, data) {
      commit('_setCurrentCurr', data)
    },
    getCurrencies({state, commit}) {
      return new Promise((resolve, reject) => {
        const ts = Math.floor(Date.now() / 1000)
        if (state.currenciesRates === null || state.lastUpdate < ts - 12*60*60) {
          commit('_resetState');
          axios.get(process.env.VUE_APP_API + '/demo/exratesjson')
            .then(resp => {
              commit('_setCurrentRates', resp.data);
              resolve(resp.data);
            })
            .catch((error) => reject(error));
        } else {
          resolve(state.currenciesRates);
        }
      })
    }
  }
}