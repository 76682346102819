export default {
    state: () => ({
    }),

    getters: {
        logooptionscounter() { return 0 },
        templateoptionscounter() { return 0 },
        presetoptionscounter() { return 0 },
        logooptions(state, getters, rootState, rootGetters) {
            return rootGetters.sharedlibrary.logos
        },
        logooptionswithempty(state, getters) {
            return [{value: '0', src: getters.noImageSrc}].concat(getters.logooptions)
        },
        templateoptions(state, getters, rootState, rootGetters) {
            return rootGetters.sharedlibrary.templates
        },
        templateoptionswithempty(state, getters) {
            return [{value: '0', src: getters.noImageSrc}].concat(getters.templateoptions)
        },
        presetoptions(state, getters, rootState, rootGetters) {
            return rootGetters.sharedlibrary.presets
        },
        noImageSrc() {
            return `${process.env.VUE_APP_URL}/images/noimage.svg`
        },
        brokenImageSrc() {
            return `${process.env.VUE_APP_URL}/images/brokenimage.svg`
        },
        libraryloaded() {
            return true
        }
    },

    mutations: {
    },

    actions: {
    },
}
