
export default {
    //namespaced: true,

    state: () => ({
        currentqr: {}
    }),

    getters: {
        getCurrentQr(state) { return state.currentqr },
    },

    mutations: {
        _setCurrentQr(state, currentqr) {
            state.currentqr = currentqr
        },
        _setCurrentQrInvalid(state) {
            state.currentqr.isnotvalid = true
        },
    },

    actions: {
        setCurrentQr({commit}, currentqr) {
            if (!currentqr.isnotvalid) {
                currentqr.isnotvalid = false
            }
            return commit('_setCurrentQr', currentqr)
        },
        resetCurrentQr({commit}) {
            commit('_setCurrentQr', {})
        },
        setCurrentQrError({commit}) {
            commit('_setCurrentQr', {error: true})
        },
        setCurrentQrInvalid({commit}) {
            commit('_setCurrentQrInvalid')
        },
        setCurrentQrLoading({commit}) {
            commit('_setCurrentQr', {loading: true, isnotvalid: false})
        },
    }
}
