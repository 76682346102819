const langs = require('@/smfm/languages.json')
const plans = require('@/smfm/plans.json')
const library = require('@/smfm/sharedlibrary.json')
const staticcardsRaw = require('@/smfm/static.json')
const dynamiccardsRaw = require('@/smfm/dynamic.json')

export default {
    //namespaced: true,

    state: () => ({
        langs: Object.freeze(langs),
        pricePlans: Object.freeze(plans),
        sharedlibrary: Object.freeze(library),
        staticcardsRaw: Object.freeze(staticcardsRaw),
        dynamiccardsRaw: Object.freeze(dynamiccardsRaw),
    }),

    getters: {
        languages(state) { return state.langs },
        pricePlans(state) { return state.pricePlans },
        sharedlibrary(state) { return state.sharedlibrary },
        staticcardsRaw(state) { return state.staticcardsRaw },
        dynamiccardsRaw(state) { return state.dynamiccardsRaw },
    },

    mutations: {
    },

    actions: {
    }
}
