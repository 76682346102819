import Vue from 'vue'
import VueI18n from 'vue-i18n'
//import axios from 'axios'
import enmessages from '@/locales/en'
import axios from "axios";
import Vuetify from '@/plugins/vuetify'
const rtllangs = require("./smfm/languages.json").filter(lang => lang.rtl).map(lang => lang.short)

Vue.use(VueI18n)

const fallbackLocale = process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en'
export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale,
  messages: {en: enmessages}
})

let loadedLanguages = ['en'] // our default language that is preloaded

function setI18nLanguage (lang) {
  i18n.locale = lang
  //axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  Vuetify.framework.rtl = rtllangs.includes(lang)
  return lang
}

export function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // TODO process requests to absent languages.

  // If the language hasn't been loaded yet
  return axios.get(`${process.env.VUE_APP_API}/demo/lang/${lang}`)
    .then(resp => {
        let messages = resp.data
        i18n.setLocaleMessage(lang, messages)
        loadedLanguages.push(lang)
        return setI18nLanguage(lang)
      })
}