const setHeadTag = (props, value, tagName = 'meta', attrName = 'content') => {
    let qs = Object.keys(props).map(k => `[${k}="${props[k]}"]`).join('')
    let el = document.querySelector(`head ${tagName}${qs}`)
    if (!el) {
        if (!value) return
        el = document.createElement(tagName)
        for (let k in props)
            el.setAttribute(k, props[k])
        document.querySelector('head').appendChild(el)
    }
    if (value) el.setAttribute(attrName, value)
    else el.remove()
};

export default {
    methods: {
        setTitle(title = null) {
            let parts = [process.env.VUE_APP_NAME]
            if (Array.isArray(title)) {
                parts = [...title, ...parts]
            } else if (title) {
                parts = [title, ...parts]
            } else {
                parts = [this.$t('app.metatitle'), ...parts]
            }
            const fulltitle = process.env.VUE_APP_TITLEPREFIX + parts.join(' • ')
            document.title = fulltitle
            setHeadTag({name: 'twitter:title'}, fulltitle)
            setHeadTag({name: 'og:title'}, fulltitle)
            this.setOgUrl((this.$route && this.$route.path) ? this.$route.path.trimRight('/') : '')
            this.setOgImage('/images/oglogo1200.png')

            // Reset all other tags.
            this.setMetaDesc(null)
            this.setMetaKeywords(null)
            setHeadTag({name: 'og:image'}, process.env.VUE_APP_URL+'/images/oglogo1200.png')

            // Set alternatives
            const alternatives = this.getAlternatives(false)
            for (let lang in alternatives)
                setHeadTag({rel: 'alternate', hreflang: lang}, process.env.VUE_APP_URL+alternatives[lang], 'link','href')
        },
        setOgUrl(url) {
            if (!url.match(/^http/)) url = process.env.VUE_APP_URL + url
            setHeadTag({rel: 'canonical'}, url, 'link', 'href')
            setHeadTag({name: 'og:url'}, url)
            setHeadTag({name: 'twitter:url'}, url)
        },
        setOgImage(url) {
            if (!url.match(/^http/)) url = process.env.VUE_APP_URL + url
            setHeadTag({name: 'og:image'}, url)
            setHeadTag({name: 'twitter:image'}, url)
        },
        setMetaDesc(desc = null) {
            const fulldesc = desc ? desc : this.$t('app.metadesc')
            setHeadTag({name: 'description'}, fulldesc)
            setHeadTag({name: 'og:description'}, fulldesc)
            setHeadTag({name: 'twitter:description'}, fulldesc)
        },
        setMetaKeywords(kw = null) {
            setHeadTag({name: 'keywords'}, kw)
        }
    }
}
