export default {
    data() {
        const urlRegex = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
        // eslint-disable-next-line no-control-regex
        const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
        // eslint-disable-next-line no-useless-escape
        const phoneRegex = /^[\d-\(\)\+ ]*$/
        const noSpacesRegex = /[^\s]+(\s+[^\s]+)*$/

        const requiredRule = v => !!v || this.$t('app.validation.fieldrequired')
        const urlRule = v => !(v || '').length || urlRegex.test(v) || this.$t('app.validation.invalidurl')
        const emailRule = v => !(v || '').length || emailRegex.test(v) || this.$t('app.validation.invalidemail')
        const phoneRule = v => !(v || '').length || phoneRegex.test(v) || this.$t('app.validation.invalidphone')
        const noSpacesRule = v => !(v || '').length || noSpacesRegex.test(v) || this.$t('app.validation.nospaces')
        return {
            requiredRule,
            urlRule,
            emailRule,
            phoneRule,
            noSpacesRule,
            maxlengthRule: maxlength => {
                return v => (v || '').length <= maxlength || this.$t('app.validation.maxlengthexceeded', {maxlength})
            },
            minlengthRule: minlength => {
                return v => (v || '').length >= minlength || this.$t('app.validation.minlengthexceeded', {minlength})
            }
        }
    }
}
